import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/vercel/path0/node_modules/.pnpm/next@15.1.6_react-dom@19.0.0_react@19.0.0__react@19.0.0/node_modules/next/dist/client/app-dir/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.1.6_react-dom@19.0.0_react@19.0.0__react@19.0.0/node_modules/next/font/google/target.css?{\"path\":\"src/components/body/index.js\",\"import\":\"Krub\",\"arguments\":[{\"weight\":[\"400\",\"600\"],\"style\":[\"normal\"],\"subsets\":[\"latin\"]}],\"variableName\":\"krub\"}");
;
import(/* webpackMode: "eager", webpackExports: ["LocalizationProvider","useLocalization"] */ "/vercel/path0/personly-libs/personly-libs-providers/dist/providers/localization.js");
;
import(/* webpackMode: "eager", webpackExports: ["MenusProvider","useMenus"] */ "/vercel/path0/personly-libs/personly-libs-providers/dist/providers/menus.js");
;
import(/* webpackMode: "eager", webpackExports: ["NotificationsProvider","useNotifications"] */ "/vercel/path0/personly-libs/personly-libs-providers/dist/providers/notifications.js");
;
import(/* webpackMode: "eager", webpackExports: ["ResourcesProvider","useResources"] */ "/vercel/path0/personly-libs/personly-libs-providers/dist/providers/resources.js");
;
import(/* webpackMode: "eager", webpackExports: ["SchemesProvider","useSchemes"] */ "/vercel/path0/personly-libs/personly-libs-providers/dist/providers/schemes.js");
;
import(/* webpackMode: "eager", webpackExports: ["StreamsProvider","useStreams"] */ "/vercel/path0/personly-libs/personly-libs-providers/dist/providers/streams.js");
;
import(/* webpackMode: "eager", webpackExports: ["SuspensionsProvider","useSuspensions"] */ "/vercel/path0/personly-libs/personly-libs-providers/dist/providers/suspensions.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/personly-libs/personly-libs-widgets/dist/widgets/button/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/personly-libs/personly-libs-widgets/dist/widgets/card-button/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/personly-libs/personly-libs-widgets/dist/widgets/checkbox/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/personly-libs/personly-libs-widgets/dist/widgets/delay/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/personly-libs/personly-libs-widgets/dist/widgets/file-source-upload/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/personly-libs/personly-libs-widgets/dist/widgets/icon-select/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/personly-libs/personly-libs-widgets/dist/widgets/masonry/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/personly-libs/personly-libs-widgets/dist/widgets/menu-links/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/personly-libs/personly-libs-widgets/dist/widgets/menu-toggler-button/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/personly-libs/personly-libs-widgets/dist/widgets/select/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/personly-libs/personly-libs-widgets/dist/widgets/text-select/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/personly-libs/personly-libs-widgets/dist/widgets/text-upload/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/personly-libs/personly-libs-widgets/dist/widgets/textarea/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/personly-libs/personly-libs-widgets/dist/widgets/time-since/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/personly-libs/personly-libs-widgets/dist/widgets/toaster/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/personly-libs/personly-libs-widgets/dist/widgets/toggle-description/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/personly-libs/personly-libs-widgets/dist/widgets/toggle/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/personly-web/personly-web-account/src/providers/errorCode.js");
;
import(/* webpackMode: "eager", webpackExports: ["LoginProvider","useLogin"] */ "/vercel/path0/personly-web/personly-web-account/src/providers/login.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/personly-web/personly-web-account/src/providers/notifications.js");
;
import(/* webpackMode: "eager", webpackExports: ["PersonalStepsProvider","usePersonalSteps"] */ "/vercel/path0/personly-web/personly-web-account/src/providers/personalSteps.js");
;
import(/* webpackMode: "eager", webpackExports: ["RegisterProvider","useRegister"] */ "/vercel/path0/personly-web/personly-web-account/src/providers/register.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/personly-web/personly-web-account/src/providers/schemes.js");
;
import(/* webpackMode: "eager", webpackExports: ["ValidatorsProvider","useValidators"] */ "/vercel/path0/personly-web/personly-web-account/src/providers/validators.js");
